import { twMerge } from "tailwind-merge";
import { BlockWrapper } from "./BlockWrapper";
import { EditableText, useInlineEditableValue } from "eddev/blocks";
import { AnimatedText } from "./AnimatedText";
import { useRef, useState } from "react";

export const AnimatedTextBlock = ({ textKey, className, textClassName, triggerStart, triggerEnd, placeholder = "Example text", repeat }: { textKey?: string; className?: string; textClassName?: string; triggerStart?: string; triggerEnd?: string; placeholder?: string; repeat?: boolean; }) => {
  const [text, setText] = useInlineEditableValue(textKey || "animatedTextBlock", placeholder)
  const [animating, setAnimating] = useState(false)
  const played = useRef(false)

  const onScroll = (trigger: ScrollTrigger) => {
    const play = trigger.progress > 0 && trigger.progress < 1

    if (repeat || (!repeat && !played.current && play)) {
      setAnimating(play)
      played.current = play || played.current
    }
  }

  return (
    <BlockWrapper ignoreToolbar className={twMerge("w-auto inline-block", className)} triggerStart={triggerStart} triggerEnd={triggerEnd} onTrigger={onScroll}>
      {env.admin && textKey ? (
        <EditableText
          as="p"
          className={textClassName}
          store={textKey}
          inlineToolbar={false}
          defaultValue={placeholder}
          placeholder={placeholder}
        />) : (
        <p className={textClassName}>
          <AnimatedText
            text={text}
            play={animating}
          />
        </p>
      )}
    </BlockWrapper>
  )
}