import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

export const AnimatedText = ({ text, className, play }: { text: string; className?: string; play?: boolean; }) => {
  const containerRef = useRef<HTMLSpanElement>(null)
  const lines = text.replace(/&amp;/g, '&').replace(/<br>/g, '\n').split('\n')
  const tl = useRef<gsap.core.Timeline>()

  useGSAP(() => {
    tl.current = gsap.timeline({ paused: true })
    let d = 0
    lines.forEach((line, index) => {
      const words = line.split(" ")
      words.forEach((word, i) => {
        const chars = word.split("")
        chars.forEach((char, j) => {
          tl.current?.add(gsap.fromTo(`.animated-text-char-${index}-${i}-${j}`, { y: 10, opacity: 0 }, { y: 0, opacity: 1, duration: 0.5 }), 0.01 * d++)
        })
      })
    })
  }, { scope: containerRef })

  useEffect(() => {
    if (play) {
      tl.current?.restart()
      // } else {
      //   tl.current?.pause()
    }
  }, [play])

  return (
    <span ref={containerRef} className={twMerge("", className)}>
      {lines.map((line, index) => (
        <span key={index} className={twMerge("block", className)}>
          {line.split(" ").map((word, i) => (
            <span key={i} className={twMerge("inline-block", className)}>
              {word.split("").map((char, j) => (
                <span key={j} className={twMerge(`animated-text-char-${index}-${i}-${j}`, "inline-block whitespace-pre", className)}>{char}</span>
              ))}
              {i < line.split(" ").length - 1 && <span className="inline-block whitespace-pre">&nbsp;</span>}
            </span>
          ))}
          {index < lines.length - 1 && <br />}
        </span>
      ))}
    </span>
  )
}